import {
  arrayToObjectAsync,
  getBaseProvidersData,
} from '../../contexts/main/getBaseProvidersData';
import { Pages } from '../../contexts/Location/LocationContext';
import {
  ControllerFlowAPI,
  ControllerParams,
  CreateControllerFn,
} from '@wix/yoshi-flow-editor';
import { renderSeoMetatagsPage } from '../../services/SeoMetatagsPage';
import { userProviderPropsMap } from '../../contexts/User/userProviderPropsMap';
import { getChallengeInitialData } from '../../contexts/storage-contexts/Challenge';
import { Referrer } from '../../contexts/storage/referrer';
import { getProgramSlug } from '../../selectors/getProgramSlug';
import { isLoggedInUser } from '../../contexts/User/helpers/userContextHelpers';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../../contexts/GeneralDataProvider/helpers/onLogin';
import { getParticipantOrderId } from '../../selectors/participants';
import { prepareUserForPayment } from './utils/prepareUserForPayment';
import { coupons } from './utils/coupons';
import { getTermsAndConditionsUrl } from './utils/getTermsAndConditionsUrl';
import { needToRedirectBackToVisitorPage } from './utils/needToRedirectBackToVisitorPage';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  return {
    async pageReady() {
      addOnLoginHandler({
        priority: IOnLoginHandlerPriority.FIRST,
        handler: async (flowAPI: ControllerFlowAPI) => {
          flowAPI.controllerConfig.setProps(
            await prepareUserForPayment(flowAPI),
          );
        },
      });
      const initialProps = {
        ...(await arrayToObjectAsync([
          getBaseProvidersData({
            flowAPI,
          }),
          getChallengeInitialData(flowAPI, Referrer.PAYMENT_PAGE),
          userProviderPropsMap(flowAPI),
          coupons(flowAPI),
          getTermsAndConditionsUrl(flowAPI),
        ])),
      };

      if (
        needToRedirectBackToVisitorPage({ initialProps, flowAPI }) &&
        flowAPI.environment.isViewer
      ) {
        const slug = getProgramSlug(initialProps?.challengeData?.challenge);
        initialProps.goToPage({
          pageId: Pages.Details,
          challengeId: slug,
        });
        return;
      } else {
        if (
          isLoggedInUser(flowAPI) &&
          !getParticipantOrderId(initialProps?.participant) &&
          flowAPI.experiments.enabled(
            'spec.programs.CreateParticipantOnPaymentPage',
          )
        ) {
          flowAPI.controllerConfig.setProps({
            ...initialProps,
            ...(await prepareUserForPayment(flowAPI)),
          });
        } else {
          flowAPI.controllerConfig.setProps({ ...initialProps });
        }
        renderSeoMetatagsPage(
          flowAPI,
          initialProps?.challengeData?.challenge,
          'PAYMENT_PAGE',
        );
      }
    },
  };
};

export default createController;
